import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomeEn from "./pages/HomeEn";
import HomeCn from "./pages/HomeCn";
import Chapter1Cn from "./pages/Chapter1Cn";
import Chapter1En from "./pages/Chapter1En";
import Chapter2Cn from "./pages/Chapter2Cn";
import Chapter2En from "./pages/Chapter2En";
import Chapter3Cn from "./pages/Chapter3Cn";
import Chapter3En from "./pages/Chapter3En";
import Chapter4Cn from "./pages/Chapter4Cn";
import Chapter4En from "./pages/Chapter4En";
import Chapter5Cn from "./pages/Chapter5Cn";
import Chapter5En from "./pages/Chapter5En";
import Chapter6Cn from "./pages/Chapter6Cn";
import Chapter6En from "./pages/Chapter6En";
import Chapter7Cn from "./pages/Chapter7Cn";
import Chapter7En from "./pages/Chapter7En";
import Chapter8Cn from "./pages/Chapter8Cn";
import Chapter8En from "./pages/Chapter8En";
import Chapter9Cn from "./pages/Chapter9Cn";
import Chapter9En from "./pages/Chapter9En";
import Chapter10Cn from "./pages/Chapter10Cn";
import Chapter10En from "./pages/Chapter10En";
import Chapter11Cn from "./pages/Chapter11Cn";
import Chapter11En from "./pages/Chapter11En";
import Chapter12Cn from "./pages/Chapter12Cn";
import Chapter12En from "./pages/Chapter12En";
import Chapter13Cn from "./pages/Chapter13Cn";
import Chapter13En from "./pages/Chapter13En";
import Chapter14Cn from "./pages/Chapter14Cn";
import Chapter14En from "./pages/Chapter14En";
import Chapter15Cn from "./pages/Chapter15Cn";
import Chapter15En from "./pages/Chapter15En";
import Chapter16Cn from "./pages/Chapter16Cn";
import Chapter16En from "./pages/Chapter16En";
import Chapter17Cn from "./pages/Chapter17Cn";
import Chapter17En from "./pages/Chapter17En";
import Chapter18Cn from "./pages/Chapter18Cn";
import Chapter18En from "./pages/Chapter18En";
import Chapter19Cn from "./pages/Chapter19Cn";
import Chapter19En from "./pages/Chapter19En";
import Chapter20Cn from "./pages/Chapter20Cn";
import Chapter20En from "./pages/Chapter20En";
import Chapter21Cn from "./pages/Chapter21Cn";
import Chapter21En from "./pages/Chapter21En";
import Chapter22Cn from "./pages/Chapter22Cn";
import Chapter22En from "./pages/Chapter22En";
import Chapter23Cn from "./pages/Chapter23Cn";
import Chapter23En from "./pages/Chapter23En";
import Chapter24Cn from "./pages/Chapter24Cn";
import Chapter24En from "./pages/Chapter24En";
import Chapter25Cn from "./pages/Chapter25Cn";
import Chapter25En from "./pages/Chapter25En";
import Chapter26Cn from "./pages/Chapter26Cn";
import Chapter26En from "./pages/Chapter26En";
import Chapter27Cn from "./pages/Chapter27Cn";
import Chapter27En from "./pages/Chapter27En";
import Chapter28Cn from "./pages/Chapter28Cn";
import Chapter28En from "./pages/Chapter28En";
import Chapter29Cn from "./pages/Chapter29Cn";
import Chapter29En from "./pages/Chapter29En";
import Chapter30Cn from "./pages/Chapter30Cn";
import Chapter30En from "./pages/Chapter30En";
import Chapter31Cn from "./pages/Chapter31Cn";
import Chapter31En from "./pages/Chapter31En";
import Chapter32Cn from "./pages/Chapter32Cn";
import Chapter32En from "./pages/Chapter32En";
import Chapter33Cn from "./pages/Chapter33Cn";
import Chapter33En from "./pages/Chapter33En";
import Chapter34Cn from "./pages/Chapter34Cn";
import Chapter34En from "./pages/Chapter34En";
import Chapter35Cn from "./pages/Chapter35Cn";
import Chapter35En from "./pages/Chapter35En";
import Chapter36Cn from "./pages/Chapter36Cn";
import Chapter36En from "./pages/Chapter36En";
import Chapter37Cn from "./pages/Chapter37Cn";
import Chapter37En from "./pages/Chapter37En";
import Chapter38Cn from "./pages/Chapter38Cn";
import Chapter38En from "./pages/Chapter38En";
import Chapter39Cn from "./pages/Chapter39Cn";
import Chapter39En from "./pages/Chapter39En";
import Chapter40Cn from "./pages/Chapter40Cn";
import Chapter40En from "./pages/Chapter40En";

import NotFound from "./pages/404";
import SettingsEn from "./pages/SettingsEn";
import SettingsCn from "./pages/SettingsCn";
import AboutEn from "./pages/AboutEn";
import AboutCn from "./pages/AboutCn";

export default class App extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<HomeEn />}>
            {/* <Route index element={<Navigate to="introduction" />} /> */}
            <Route path="introduction">
              <Route index element={<Navigate to="english" />} />
              <Route path="chinese" element={<HomeCn />} />
              <Route path="english" element={<HomeEn />} />
            </Route>
          </Route>
          <Route path="settings">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<SettingsCn />} />
            <Route path="english" element={<SettingsEn />} />
          </Route>
          <Route path="about">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<AboutCn />} />
            <Route path="english" element={<AboutEn />} />
          </Route>
          <Route path="chapter-1">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter1Cn />} />
            <Route path="english" element={<Chapter1En />} />
          </Route>
          <Route path="chapter-2">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter2Cn />} />
            <Route path="english" element={<Chapter2En />} />
          </Route>
          <Route path="chapter-3">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter3Cn />} />
            <Route path="english" element={<Chapter3En />} />
          </Route>
          <Route path="chapter-4">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter4Cn />} />
            <Route path="english" element={<Chapter4En />} />
          </Route>
          <Route path="chapter-5">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter5Cn />} />
            <Route path="english" element={<Chapter5En />} />
          </Route>
          <Route path="chapter-6">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter6Cn />} />
            <Route path="english" element={<Chapter6En />} />
          </Route>
          <Route path="chapter-7">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter7Cn />} />
            <Route path="english" element={<Chapter7En />} />
          </Route>
          <Route path="chapter-8">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter8Cn />} />
            <Route path="english" element={<Chapter8En />} />
          </Route>
          <Route path="chapter-9">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter9Cn />} />
            <Route path="english" element={<Chapter9En />} />
          </Route>
          <Route path="chapter-10">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter10Cn />} />
            <Route path="english" element={<Chapter10En />} />
          </Route>
          <Route path="chapter-11">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter11Cn />} />
            <Route path="english" element={<Chapter11En />} />
          </Route>
          <Route path="chapter-12">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter12Cn />} />
            <Route path="english" element={<Chapter12En />} />
          </Route>
          <Route path="chapter-13">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter13Cn />} />
            <Route path="english" element={<Chapter13En />} />
          </Route>
          <Route path="chapter-14">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter14Cn />} />
            <Route path="english" element={<Chapter14En />} />
          </Route>
          <Route path="chapter-15">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter15Cn />} />
            <Route path="english" element={<Chapter15En />} />
          </Route>
          <Route path="chapter-16">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter16Cn />} />
            <Route path="english" element={<Chapter16En />} />
          </Route>
          <Route path="chapter-17">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter17Cn />} />
            <Route path="english" element={<Chapter17En />} />
          </Route>
          <Route path="chapter-18">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter18Cn />} />
            <Route path="english" element={<Chapter18En />} />
          </Route>
          <Route path="chapter-19">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter19Cn />} />
            <Route path="english" element={<Chapter19En />} />
          </Route>
          <Route path="chapter-20">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter20Cn />} />
            <Route path="english" element={<Chapter20En />} />
          </Route>
          <Route path="chapter-21">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter21Cn />} />
            <Route path="english" element={<Chapter21En />} />
          </Route>
          <Route path="chapter-22">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter22Cn />} />
            <Route path="english" element={<Chapter22En />} />
          </Route>
          <Route path="chapter-23">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter23Cn />} />
            <Route path="english" element={<Chapter23En />} />
          </Route>
          <Route path="chapter-24">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter24Cn />} />
            <Route path="english" element={<Chapter24En />} />
          </Route>
          <Route path="chapter-25">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter25Cn />} />
            <Route path="english" element={<Chapter25En />} />
          </Route>
          <Route path="chapter-26">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter26Cn />} />
            <Route path="english" element={<Chapter26En />} />
          </Route>
          <Route path="chapter-27">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter27Cn />} />
            <Route path="english" element={<Chapter27En />} />
          </Route>
          <Route path="chapter-28">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter28Cn />} />
            <Route path="english" element={<Chapter28En />} />
          </Route>
          <Route path="chapter-29">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter29Cn />} />
            <Route path="english" element={<Chapter29En />} />
          </Route>
          <Route path="chapter-30">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter30Cn />} />
            <Route path="english" element={<Chapter30En />} />
          </Route>
          <Route path="chapter-31">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter31Cn />} />
            <Route path="english" element={<Chapter31En />} />
          </Route>
          <Route path="chapter-32">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter32Cn />} />
            <Route path="english" element={<Chapter32En />} />
          </Route>
          <Route path="chapter-33">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter33Cn />} />
            <Route path="english" element={<Chapter33En />} />
          </Route>
          <Route path="chapter-34">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter34Cn />} />
            <Route path="english" element={<Chapter34En />} />
          </Route>
          <Route path="chapter-35">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter35Cn />} />
            <Route path="english" element={<Chapter35En />} />
          </Route>
          <Route path="chapter-36">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter36Cn />} />
            <Route path="english" element={<Chapter36En />} />
          </Route>
          <Route path="chapter-37">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter37Cn />} />
            <Route path="english" element={<Chapter37En />} />
          </Route>
          <Route path="chapter-38">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter38Cn />} />
            <Route path="english" element={<Chapter38En />} />
          </Route>
          <Route path="chapter-39">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter39Cn />} />
            <Route path="english" element={<Chapter39En />} />
          </Route>
          <Route path="chapter-40">
            <Route index element={<Navigate to="english" />} />
            <Route path="chinese" element={<Chapter40Cn />} />
            <Route path="english" element={<Chapter40En />} />
          </Route>
        </Routes>
      </>
    );
  }
}
