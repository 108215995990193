import React from "react";
import { Helmet } from "react-helmet-async";

// Test your pages here: https://search.google.com/test/rich-results

const Seo = ({ index, pageTitle, translation }) => {
  // noindex is only for the /settings page
  const robotsTag =
    pageTitle === "道德经 - 老子 - 设置" ||
    pageTitle === "The Dao de Jing by Lao Zi - Settings"
      ? "noindex"
      : null;

  const keywordsCn = [
    ["道德经简介，道德经，道教"],
    ["道德经, 第一章, 道可道, 非常道"],
    ["道德经, 第二章, 天下皆知美之为美"],
    ["道德经, 第三章, 不尚贤, 使民不争"],
    ["道德经, 第四章 道冲而用之或不盈"],
    ["道德经, 第五章, 多言数穷, 不如守中"],
    ["道德经, 第六章, 谷神不死"],
    ["道德经, 第七章, 天长地久"],
    ["道德经, 第八章, 上善若水"],
    ["道德经, 第九章, 功成身退, 天之道"],
    ["道德经, 第十章, 生而不有, 为而不恃"],
    ["道德经, 第十一章, 有之以为利, 无之以为用"],
    ["道德经, 第十二章, 五色令人目盲"],
    ["道德经, 第十三章, 宠辱若惊, 贵大患若身"],
    ["道德经, 第十四章, 视之不见名曰夷"],
    ["道德经, 第十五章, 古之善为士者"],
    ["道德经, 第十六章, 致虚极, 守静笃"],
    ["道德经, 第十七章, 太上, 下知有之"],
    ["道德经, 第十八章, 大道废, 有仁义"],
    ["道德经, 第十九章, 绝圣弃智, 民利百倍"],
    ["道德经, 第二十章, 绝学无忧"],
    ["道德经, 第二十一章, 孔德之容, 惟道是从"],
    ["道德经, 第二十二章, 曲则全"],
    ["道德经, 第二十三章, 希言自然"],
    ["道德经, 第二十四章, 企者不立, 跨者不行"],
    ["道德经, 第二十五章, 有物混5成, 先天地生"],
    ["道德经, 第二十六章, 轻则失本, 躁则失君"],
    ["道德经, 第二十七章, 善行无辙迹"],
    ["道德经, 第二十八章, 知其雄, 守其雌"],
    ["道德经, 第二十九章, 将欲取天下而为之"],
    ["道德经, 第三十章, 以道佐人主者, 不以兵强天下"],
    ["道德经, 第三十一章, 夫佳兵者, 不祥之器"],
    ["道德经, 第三十二章, 道常无名"],
    ["道德经, 第三十三章, 知人者智, 自知者明"],
    ["道德经, 第三十四章, 大道泛兮, 其可左右"],
    ["道德经, 第三十五章, 执大象, 天下往"],
    ["道德经, 第三十六章, 将欲歙之, 必固张之"],
    ["道德经, 第三十七章, 道常无为而无不为"],
    ["道德经, 第三十八章, 上德不德, 是以有德"],
    ["道德经, 第三十九章, 昔之得一者, 天得一以清"],
    ["道德经, 第四十章, 有生于无"],
    [
      "关于道德经的APP, 学习道德经, 用普通话听每一章，用简体字阅读成绩单，用英文复习翻译,",
    ],
    [""],
  ];

  const keywordsEn = [
    [
      "Dao de Jing, Tao te Ching, Lao zi, Lao Tsu, Introduction to the Dao de Jing, daoism, laozi, best tao te ching translation, tao te ching audio, chinese classic text, The best audio mobile app to learn the Dao de Jing in Mandarin and in English",
    ],
    [
      "The Way that can be followed is not the eternal Way, Tao Te Ching, daoism",
    ],
    [
      "All in the world recognize the beautiful as beautiful, Tao Te Ching, daoism",
    ],
    ["If you do not adulate the worthy, Tao Te Ching, daoism"],
    ["The Way is so vast, Tao Te Ching, daoism"],
    ["Heaven and Earth are not humane, Tao Te Ching, daoism"],
    ["The valley spirit never dies, Tao Te Ching, daoism"],
    ["Heaven and Earth last forever, Tao Te Ching, daoism"],
    ["The highest goodness is like water, Tao Te Ching, daoism"],
    ["To hold until full is not as good as stopping, Tao Te Ching, daoism"],
    ["Pacifying the agitated material soul, Tao Te Ching, daoism"],
    ["Thirty spokes join together in the hub, Tao Te Ching, daoism"],
    ["The five colors blind our eyes, Tao Te Ching, daoism"],
    ["Accept humiliation as a surprise, Tao Te Ching, daoism"],
    ["Look for it, it cannot be seen, Tao Te Ching, daoism"],
    ["The ancient masters of the Way, Tao Te Ching, daoism"],
    ["Effect vacuity to the extreme, Tao Te Ching, daoism"],
    [
      "From great antiquity forth they have known and possessed it, Tao Te Ching, daoism",
    ],
    ["When the great Way perishes, Tao Te Ching, daoism"],
    ["Get rid of holiness, Tao Te Ching, daoism"],
    ["Get rid of learning, Tao Te Ching, daoism"],
    ["The form of great virtue, Tao Te Ching, daoism"],
    ["The imperfect is completed, Tao Te Ching, daoism"],
    ["To speak little is natural, Tao Te Ching, daoism"],
    ["Standing on tiptoe, you are unsteady, Tao Te Ching, daoism"],
    [
      "There is something that is perfect in its disorder, Tao Te Ching, daoism",
    ],
    ["Heaviness is the root of lightness, Tao Te Ching, daoism"],
    ["A good traveler leaves no tracks, Tao Te Ching, daoism"],
    ["Know the Masculine, cleave to the Feminine, Tao Te Ching, daoism"],
    ["If you want to grab the world and run it, Tao Te Ching, daoism"],
    ["If you used the Way as a principle for ruling, Tao Te Ching, daoism"],
    ["Sharp weapons are inauspicious instruments, Tao Te Ching, daoism"],
    ["The Way is always nameless, Tao Te Ching, daoism"],
    ["If you understand others you are smart, Tao Te Ching, daoism"],
    ["The great Way flows over, Tao Te Ching, daoism"],
    ["Grasping to the Great Form, Tao Te Ching, daoism"],
    ["That which will be shrunk, Tao Te Ching, daoism"],
    ["The Way is always not-doing, Tao Te Ching, daoism"],
    ["Superior virtue is not virtuous, Tao Te Ching, daoism"],
    ["These in the past have attained wholeness, Tao Te Ching, daoism"],
    ["Return is the motion of the Way, Tao Te Ching, daoism"],
    ["When superior students hear of the Way, Tao Te Ching, daoism"],
    ["The Way produces one, one produces two, Tao Te Ching, daoism"],
    ["The softest thing in the world, Tao Te Ching, daoism"],
    ["Which is dearer, fame or your life, Tao Te Ching, daoism"],
    ["Great perfection seems flawed, Tao Te Ching, daoism"],
    ["When the Way prevails in the land, Tao Te Ching, daoism"],
    ["Without going out the door, knowing everything, Tao Te Ching, daoism"],
    ["In studying, each day something is gained, Tao Te Ching, daoism"],
    ["The sage has no fixed mind, Tao Te Ching, daoism"],
    ["Coming into life and entering death, Tao Te Ching, daoism"],
    ["Way gives birth to it, Tao Te Ching, daoism"],
    [
      "All things have a beginning, which we can regard as their Mother, Tao Te Ching, daoism",
    ],
    ["If I had just a little bit of wisdom, Tao Te Ching, daoism"],
    ["The well-established cannot be uprooted, Tao Te Ching, daoism"],
    ["One who remains rich in virtuous power, Tao Te Ching, daoism"],
    ["One who knows does not speak, Tao Te Ching, daoism"],
    ["Use fairness in governing the state, Tao Te Ching, daoism"],
    ["When the government is laid back, Tao Te Ching, daoism"],
    ["In governing the country and serving Heaven, Tao Te Ching, daoism"],
    [
      "Governing a large country is like cooking a small fish, Tao Te Ching, daoism",
    ],
    ["The great state should be like a river basin, Tao Te Ching, daoism"],
    ["The Way is hidden deeply in all things, Tao Te Ching, daoism"],
    ["Do without doing, Tao Te Ching, daoism"],
    ["That which is at rest is easy to grasp, Tao Te Ching, daoism"],
    ["The ancients who were skillful at the Way, Tao Te Ching, daoism"],
    ["The reason the river and sea can be regarded as, Tao Te Ching, daoism"],
    ["The reason everybody calls my Way great, Tao Te Ching, daoism"],
    ["The best warrior is never aggressive, Tao Te Ching, daoism"],
    ["Strategists have a saying, Tao Te Ching, daoism"],
    ["My words are easy to understand, Tao Te Ching, daoism"],
    [
      "There is nothing better than to know that you don't know, Tao Te Ching, daoism",
    ],
    ["When the people do not fear your might, Tao Te Ching, daoism"],
    ["If you are courageous in daring you will die, Tao Te Ching, daoism"],
    ["If the people don't fear death, Tao Te Ching, daoism"],
    ["The reason people starve, Tao Te Ching, daoism"],
    ["When people are born they are gentle and soft, Tao Te Ching, daoism"],
    ["The Way of Heaven, Tao Te Ching, daoism"],
    ["Nothing in the world is softer than water, Tao Te Ching, daoism"],
    ["After calming great anger, Tao Te Ching, daoism"],
    ["Let there be a small country with few people, Tao Te Ching, daoism"],
    ["True words are not fancy, Tao Te Ching, daoism"],
    [
      "Best online resource to learn the Dao de Jing, Listen to each chapter Dao de Jing in Mandarin, read the transcript of Dao de Jing, Dao de Jing translation, Tao te Ching translation, Dao de Jing, Tao te Ching, Laozi, Lao Tsu, Tao te Ching translation, dao de jing translation, chinese classic text",
    ],
    [""],
  ];

  const keywords = translation ? keywordsEn[index] : keywordsCn[index];
  const description = translation
    ? "The Dao de Jing is one of the most fascinating ancient Chinese poems. With this new app, you can study the Dao de Jing in Chinese and read its translation in English."
    : "道德经可以说是历史上最重要的文字之一。这是一首优美的经典诗歌，约 5,000 字，分 81 章。 《道德经》传统上归功于公元前 6 世纪的圣人老子";
  const language = translation ? "en" : "zn";

  const structuredData = {
    "@context": "https://schema.org/",
    type: "Audiobook",
    "@id": "https://dao-de-jing.com/",
    name: "The Dao de Jing by Lao Zi",
    abridged: "false",
    author: "Lao Zi",
    keywords: keywords,
    description:
      "The Dao de Jing is arguably among the most important pieces of text ever written in history. It is a beautiful classic poem of about 5,000 characters organized in 81 chapters. The Dao de Jing is traditionally credited to the 6th-century BC sage Lao Zi.",
    datePublished: "-500-01-01",
    numberOfPages: "81",
    logo: "https://dao-de-jing.com/logo.svg",
    image: "https://dao-de-jing.com/ss2.png",
    inLanguage: language,
    duration: "PT0H45M39S",
    accessMode: "textual, visual, auditory",
    encodingFormat: "audio/mp3",
    playerType: "mp3 compatible",
    provider: "The Dao de Jing Foundation",
    publisher: "The Dao de Jing Foundation",
    contentUrl: "https://dao-de-jing.com/about/english/",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.8",
      reviewCount: "2305",
    },
  };

  // console.log("robotstags", robotsTag);
  // console.log("index", index);
  // console.log("keywords", keywords);
  // console.log("description", description);

  return (
    <>
      <Helmet>
        {/* title tag should be 50 to 60 characters */}
        <title>{pageTitle && `${pageTitle}`}</title>

        {/* keywords */}
        <meta name="keywords" content={keywords} />

        {/* description tag maximum 150-160 characters (including spaces). */}
        <meta name="description" content={description} />

        {/* Robots tags */}
        <meta name="robots" content={robotsTag} />

        {/* structured data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dao-de-jing.com/" />
        <meta property="og:title" content={pageTitle && `${pageTitle}`} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://dao-de-jing.com/ss1.png" />

        {/* Twitter */}
        <meta property="twitter:card" content="website" />
        <meta property="twitter:url" content="https://dao-de-jing.com/" />
        <meta property="twitter:title" content={pageTitle && `${pageTitle}`} />
        <meta property="twitter:description" content={description} />
        <meta
          property="twitter:image"
          content="https://dao-de-jing.com/ss1.png"
        />

        <link
          rel="apple-touch-icon"
          href="https://dao-de-jing.com/logo192.png"
        />
        <link rel="manifest" href="https://dao-de-jing.com/manifest.json" />

        {/* the bellow two lines attempt to hide the address bar */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />

        {/* the following line improves the PWA compatibility on a variety of devices */}
        <script
          async
          src="https://unpkg.com/pwacompat"
          crossorigin="anonymous"
        ></script>
      </Helmet>
    </>
  );
};

export default Seo;
