const audioDurations = [
  "00:14",
  "00:34",
  "00:45",
  "00:34",
  "00:23",
  "00:25",
  "00:15",
  "00:25",
  "00:30",
  "00:22",
  "00:37",
  "00:25",
  "00:26",
  "00:40",
  "00:45",
  "00:46",
  "00:37",
  "00:26",
  "00:18",
  "00:24",
  "01:06",
  "00:36",
  "00:40",
  "00:41",
  "00:25",
  "00:43",
  "00:24",
  "00:44",
  "00:46",
  "00:33",
  "00:36",
  "00:54",
  "00:35",
  "00:22",
  "00:29",
  "00:24",
  "00:28",
  "00:26",
  "01:02",
  "01:11",
  "00:12",
];

const audioList = [
  {
    nameCn: "道德经",
    nameEn: "Introduction",
    duration: audioDurations[0],
  },
  {
    nameCn: "第一章",
    nameEn: "Chapter 1",
    duration: audioDurations[1],
  },
  {
    nameCn: "第二章",
    nameEn: "Chapter 2",
    duration: audioDurations[2],
  },
  {
    nameCn: "第三章",
    nameEn: "Chapter 3",
    duration: audioDurations[3],
  },
  {
    nameCn: "第四章",
    nameEn: "Chapter 4",
    duration: audioDurations[4],
  },
  {
    nameCn: "第五章",
    nameEn: "Chapter 5",
    duration: audioDurations[5],
  },
  {
    nameCn: "第六章",
    nameEn: "Chapter 6",
    duration: audioDurations[6],
  },
  {
    nameCn: "第七章",
    nameEn: "Chapter 7",
    duration: audioDurations[7],
  },
  {
    nameCn: "第八章",
    nameEn: "Chapter 8",
    duration: audioDurations[8],
  },
  {
    nameCn: "第九章",
    nameEn: "Chapter 9",
    duration: audioDurations[9],
  },
  {
    nameCn: "第十章",
    nameEn: "Chapter 10",
    duration: audioDurations[10],
  },
  {
    nameCn: "第十一章",
    nameEn: "Chapter 11",
    duration: audioDurations[11],
  },
  {
    nameCn: "第十二章",
    nameEn: "Chapter 12",
    duration: audioDurations[12],
  },
  {
    nameCn: "第十三章",
    nameEn: "Chapter 13",
    duration: audioDurations[13],
  },
  {
    nameCn: "第十四章",
    nameEn: "Chapter 14",
    duration: audioDurations[14],
  },
  {
    nameCn: "第十五章",
    nameEn: "Chapter 15",
    duration: audioDurations[15],
  },
  {
    nameCn: "第十六章",
    nameEn: "Chapter 16",
    duration: audioDurations[16],
  },
  {
    nameCn: "第十七章",
    nameEn: "Chapter 17",
    duration: audioDurations[17],
  },
  {
    nameCn: "第十八章",
    nameEn: "Chapter 18",
    duration: audioDurations[18],
  },
  {
    nameCn: "第十九章",
    nameEn: "Chapter 19",
    duration: audioDurations[19],
  },
  {
    nameCn: "第二十章",
    nameEn: "Chapter 20",
    duration: audioDurations[20],
  },
  {
    nameCn: "第二十一章",
    nameEn: "Chapter 21",
    duration: audioDurations[21],
  },
  {
    nameCn: "第二十二章",
    nameEn: "Chapter 22",
    duration: audioDurations[22],
  },
  {
    nameCn: "第二十三章",
    nameEn: "Chapter 23",
    duration: audioDurations[23],
  },
  {
    nameCn: "第二十四章",
    nameEn: "Chapter 24",
    duration: audioDurations[24],
  },
  {
    nameCn: "第二十五章",
    nameEn: "Chapter 25",
    duration: audioDurations[25],
  },
  {
    nameCn: "第二十六章",
    nameEn: "Chapter 26",
    duration: audioDurations[26],
  },
  {
    nameCn: "第二十七章",
    nameEn: "Chapter 27",
    duration: audioDurations[27],
  },
  {
    nameCn: "第二十八章",
    nameEn: "Chapter 28",
    duration: audioDurations[28],
  },
  {
    nameCn: "第二十九章",
    nameEn: "Chapter 29",
    duration: audioDurations[29],
  },
  {
    nameCn: "第三十章",
    nameEn: "Chapter 30",
    duration: audioDurations[30],
  },
  {
    nameCn: "第三十一章",
    nameEn: "Chapter 31",
    duration: audioDurations[31],
  },
  {
    nameCn: "第三十二章",
    nameEn: "Chapter 32",
    duration: audioDurations[32],
  },
  {
    nameCn: "第三十三章",
    nameEn: "Chapter 33",
    duration: audioDurations[33],
  },
  {
    nameCn: "第三十四章",
    nameEn: "Chapter 34",
    duration: audioDurations[34],
  },
  {
    nameCn: "第三十五章",
    nameEn: "Chapter 35",
    duration: audioDurations[35],
  },
  {
    nameCn: "第三十六章",
    nameEn: "Chapter 36",
    duration: audioDurations[36],
  },
  {
    nameCn: "第三十七章",
    nameEn: "Chapter 37",
    duration: audioDurations[37],
  },
  {
    nameCn: "第三十八章",
    nameEn: "Chapter 38",
    duration: audioDurations[38],
  },
  {
    nameCn: "第三十九章",
    nameEn: "Chapter 39",
    duration: audioDurations[39],
  },
  {
    nameCn: "第四十章",
    nameEn: "Chapter 40",
    duration: audioDurations[40],
  },
];
export default audioList;
