import React from "react";
import { useReactPWAInstall } from "react-pwa-install";
import myLogo from "../img/logo.svg";
import "../css/player.scss";

export default function InstallPWA(prop) {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const popupText = (
    <ul>
      <li>Install the app to your home screen</li>
      <li>Enjoy the app offline, and don't worry about data usage</li>
      <li>
        To manually install (or reinstall) this app just click on the menu
        button on your browser, then click of 'Install'
      </li>
    </ul>
  );

  const handleClick = () => {
    pwaInstall({
      title: "Install the Dao de Jing App",
      logo: myLogo,
      features: popupText,
      description:
        "Once installed, you will be able to open this app by clicking on an icon on your home screen.",
    })
      .then(() =>
        console.log(
          "App installed successfully OR instructions for install shown"
        )
      )
      .catch(() =>
        console.log(
          "User opted out from installing OR system not compatible with PWA"
        )
      );
  };

  return (
    <>
      {supported() && !isInstalled() && (
        <>
          <div className="setting-text">
            {prop.language
              ? "Install this light app on your homescreen and use it without a connection to the Internet."
              : "下载这个APP (无需互联网即可使用)"}
          </div>
          <div className="setting-button">
            <button
              type="button"
              className="install-button"
              id="install-button"
              aria-label="Install app"
              title="Install app"
              onClick={handleClick}
            >
              {prop.language ? "Install" : "下载"}
            </button>
          </div>
        </>
      )}
    </>
  );
}
