import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      {/* !! IMPORTANT !! if the pageTitle changes, you must update the 
    robot tags in the Seo component*/}
      <Seo index={42} pageTitle="道德经 - 老子 - 设置" translation={false} />
      <Player chapter={0} translation={false} settings={true} />
    </>
  );
};

export default index;
