import ToggleButton from "./ToggleButton";
import InstallPWA from "./InstallPWA";
import { Player } from "./Player";

export default function settingsContent(stop, clickNplay, loop, translation) {
  return (
    <>
      <div className="text-card-settings">
        <h2>{translation ? "Settings" : "设置"}</h2>
        <div className="item-switch-container">
          <div className="setting-text">
            {translation ? "Pause at the end of a track" : "在曲目结束时暂停"}
          </div>
          <div className="setting-button">
            <ToggleButton
              name="stop"
              isOn={stop}
              handleToggle={() => {
                localStorage.setItem("stop", JSON.stringify(!stop));
                if (!stop) {
                  localStorage.setItem("loop", JSON.stringify(false));
                }
              }}
            />
          </div>
        </div>
        <div className="item-switch-container">
          <div className="setting-text">
            {translation
              ? "Play a track when clicking on it"
              : "单击曲目时播放曲目"}
          </div>
          <div className="setting-button">
            <ToggleButton
              name="clickNplay"
              isOn={clickNplay}
              handleToggle={() =>
                localStorage.setItem("clickNplay", JSON.stringify(!clickNplay))
              }
            />
          </div>
        </div>
        <div className="item-switch-container">
          <div className="setting-text">
            {translation ? "Play track in a loop" : "循环播放曲目"}
          </div>
          <div className="setting-button">
            <ToggleButton
              name="loop"
              isOn={loop}
              handleToggle={() => {
                localStorage.setItem("loop", JSON.stringify(!loop));
                if (!loop) {
                  localStorage.setItem("stop", JSON.stringify(false));
                }
              }}
            />
          </div>
        </div>
        {/* <div className="item-switch-container">
          <div className="setting-text">
            {translation ? "切换到中文" : "Toggle to English"}
          </div>
          <div className="setting-button">
            <div
              className={translation ? "setting-cn-flag" : "setting-en-flag"}
            ></div> 
          </div>
        </div> */}
        <div className="item-switch-container">
          <InstallPWA language={translation} />
        </div>
      </div>
    </>
  );
}
