import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      {/* !! IMPORTANT !! if the pageTitle changes, you must update the 
    robot tags in the Seo component*/}
      <Seo
        index={41}
        pageTitle="道德经 - 老子 - 关于这个APP"
        translation={false}
      />
      <Player chapter={0} translation={false} info={true} />
    </>
  );
};

export default index;
