import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import ReactPWAInstallProvider from "react-pwa-install";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./css/loader.css";

ReactDOM.render(
  <ReactPWAInstallProvider enableLogging>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </ReactPWAInstallProvider>,
  document.querySelector("#root")
);

serviceWorkerRegistration.register();
