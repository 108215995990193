import { Link } from "react-router-dom";
import Seo from "../utils/seo";

export default function NotFound() {
  return (
    <>
      <Seo pageTitle="Page not Found - The Dao de Jing by Lao Zi" />
      <div className="not_found">
        <div className="loader smaller" />
        <div className="not_found text">
          <h1>Oops! This page doesn't follow the Way!</h1>
          <b>
            <p>But you may still find the Way here: </p>
            <Link to="/">Home</Link>
            <p>此网页找不到! 是谓不道，不道早已! </p>
            <p>返回主页:</p>
            <Link to="/">主页</Link>
          </b>
        </div>
      </div>
    </>
  );
}
