import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      {/* !! IMPORTANT !! if the pageTitle changes, you must update the 
    robot tags in the Seo component*/}
      <Seo
        index={83}
        pageTitle="The Dao de Jing by Lao Zi - Settings"
        translation={true}
      />
      <Player chapter={0} translation={true} settings={true} />
    </>
  );
};

export default index;
