import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo
        index={29}
        pageTitle="The Dao de Jing by Lao Zi - Chapter 29"
        translation={true}
      />
      <Player chapter={29} translation={true} />
    </>
  );
};

export default index;
