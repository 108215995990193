import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo index={2} pageTitle="道德经 - 老子 - 第二章" translation={false} />
      <Player chapter={2} translation={false} />
    </>
  );
};

export default index;
