import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo index={7} pageTitle="道德经 - 老子 - 第七章" translation={false} />
      <Player chapter={7} translation={false} />
    </>
  );
};

export default index;
