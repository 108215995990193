import React from "react";
import settingsContent from "./SettingsContent";
import audioList from "../utils/audioList";
import data from "../utils/fetchData";
import { marked } from "marked";
import "../css/player.scss";
// import ButtonPWA from "./ButtonPWA";

const infoText = [];

function fetchInfo(arg) {
  const importedText = require(`../content/${arg}.md`);
  fetch(importedText)
    .then((response) => {
      return response.text();
    })
    .then((text) => {
      infoText.push(marked(text));
    });
}

fetchInfo("english/info");
fetchInfo("mandarin/info");

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chapter: this.props.chapter,
      index: 0,
      currentTime: "00:00",
      audioList: audioList,
      pause: false,
      translation: this.props.translation,
      info: this.props.info,
      settings: this.props.settings,
      myHistory: null,
    };
  }

  componentDidMount() {
    this.playerRef.addEventListener("timeupdate", this.timeUpdate, false);
    this.playerRef.addEventListener("ended", this.whenTrackEnds, false);
    this.timelineRef.addEventListener("click", this.changeCurrentTime, false);
    this.timelineRef.addEventListener("mousemove", this.hoverTimeLine, false);
    this.timelineRef.addEventListener("mouseout", this.resetTimeLine, false);
    this.updatePlayer();
    // const self = this;
    // if (self.state.chapter >= 0) {
    //   this.localStorage.setItem("index", JSON.stringify(self.state.chapter));
    // }
    this.setState({ chapter: undefined });
  }

  componentWillUnmount() {
    this.playerRef.removeEventListener("timeupdate", this.timeUpdate);
    this.playerRef.removeEventListener("ended", this.whenTrackEnds);
    this.timelineRef.removeEventListener("click", this.changeCurrentTime);
    this.timelineRef.removeEventListener("mousemove", this.hoverTimeLine);
    this.timelineRef.removeEventListener("mouseout", this.resetTimeLine);
  }

  changeCurrentTime = (e) => {
    const duration = this.playerRef.duration;
    const playheadWidth = this.timelineRef.offsetWidth;
    const offsetWidth = this.timelineRef.offsetLeft;
    const userClickWidth = e.clientX - offsetWidth;
    const userClickWidthInPercent = (userClickWidth * 100) / playheadWidth;
    this.playheadRef.style.width = userClickWidthInPercent + "%";
    this.playerRef.currentTime = (duration * userClickWidthInPercent) / 100;
  };

  timeUpdate = () => {
    const duration = this.playerRef.duration;
    const playPercent = 100 * (this.playerRef.currentTime / duration);
    this.playheadRef.style.width = playPercent + "%";
    const currentTime = this.formatTime(parseInt(this.playerRef.currentTime));
    this.setState({
      currentTime,
    });
  };

  formatTime = (currentTime) => {
    const minutes = Math.floor(currentTime / 60);
    let seconds = Math.floor(currentTime % 60);
    seconds = seconds >= 10 ? seconds : "0" + (seconds % 60);
    const formatTime = `${minutes}0:${seconds}`;
    return formatTime;
  };

  getIndex = () => {
    const { chapter } = this.state;
    const index =
      chapter >= 0
        ? chapter
        : localStorage.getItem("index")
        ? JSON.parse(localStorage.getItem("index"))
        : 0;
    if (chapter >= 0) {
      localStorage.setItem("index", JSON.stringify(chapter));
    }
    return index;
  };

  updatePlayer = () => {
    const index = this.getIndex();
    this.playerRef.src = data().allAudioFiles[`${index}.mp3`];
    this.playerRef.load();
    const playItem = document.getElementById("track" + index);
    playItem.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  backFive = () => {
    const newTime = this.playerRef.currentTime - 5;
    this.playerRef.currentTime = newTime;
  };

  forwardFive = () => {
    const newTime = this.playerRef.currentTime + 5;
    this.playerRef.currentTime = newTime;
  };

  nextTrack = () => {
    const index = this.getIndex();
    const { audioList, pause } = this.state;
    const newIndex = (index + 1) % audioList.length;
    this.fetchText(newIndex);
    localStorage.setItem("index", JSON.stringify(newIndex));
    this.updatePlayer();
    if (pause) {
      this.playerRef.play();
    }
  };

  whenTrackEnds = () => {
    const stop = localStorage.getItem("stop")
      ? JSON.parse(localStorage.getItem("stop"))
      : false;
    const loop = localStorage.getItem("loop")
      ? JSON.parse(localStorage.getItem("loop"))
      : false;
    if (stop) {
      this.playerRef.pause();
      this.setState({
        pause: false,
      });
    }
    if (loop && !stop) {
      this.playerRef.play();
    }
    if (!stop && !loop) {
      this.nextTrack();
      this.updateSlug();
      this.setState({
        pause: true,
      });
    }
  };

  playOrPause = () => {
    const index = this.getIndex();
    const { pause } = this.state;
    this.fetchText(index);
    if (!this.state.pause) {
      this.playerRef.play();
    } else {
      this.playerRef.pause();
    }
    this.setState(
      {
        pause: !pause,
        info: false,
        settings: false,
      },
      this.updateSlug
    );
    const playItem = document.getElementById("track" + index);
    playItem.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  updateSlug = () => {
    const { translation, myHistory, settings, info } = this.state;
    const index = this.getIndex();
    const pageTitle = index === 0 ? "Home" : `Chapter ${index}`;
    const chapterSlug = index === 0 ? "introduction" : `chapter-${index}`;
    const languageSlug = translation ? "english" : "chinese";
    const newSlug = settings
      ? "/settings"
      : info
      ? `/about/${languageSlug}`
      : `/${chapterSlug}/${languageSlug}`;
    window.history.pushState(myHistory, pageTitle, newSlug);
    this.setState({ myHistory: `${chapterSlug}/${languageSlug}` });
  };

  clickAudio = (key) => {
    const { pause } = this.state;
    const clickNplay = localStorage.getItem("clickNplay")
      ? JSON.parse(localStorage.getItem("clickNplay"))
      : true;
    this.setState(
      {
        index: key,
        info: false,
        settings: false,
      },
      this.updateSlug
    );
    localStorage.setItem("index", JSON.stringify(key));
    this.fetchText(key);
    this.updatePlayer();
    if (!pause && clickNplay) {
      this.setState({
        pause: !pause,
      });
      this.playerRef.play();
    }
    if ((!pause && !clickNplay) || (pause && !clickNplay)) {
      this.setState({
        pause: false,
      });
    }
    if (pause && clickNplay) {
      this.playerRef.play();
    }
  };

  infoApp = () => {
    const { pause } = this.state;
    this.setState(
      {
        mandarin: infoText[0],
        english: infoText[1],
        info: true,
        settings: false,
      },
      this.updateSlug
    );

    if (pause) {
      this.playerRef.pause();
      this.setState({
        pause: !pause,
      });
    }
  };

  settingsOpen = () => {
    const { pause, settings } = this.state;
    if (pause) {
      this.playerRef.pause();
      this.setState({
        pause: !pause,
      });
    }
    if (!settings) {
      this.setState(
        {
          settings: true,
        },
        this.updateSlug
      );
    } else {
      this.setState(
        {
          settings: false,
        },
        this.updateSlug
      );
    }
  };

  fetchText = (index) => {
    const mandarinText = require(`../content/english/${index}.md`);
    const englishText = require(`../content/mandarin/${index}.md`);
    fetch(mandarinText)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        this.setState({
          mandarin: marked(text),
        });
      });

    fetch(englishText)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        this.setState({
          english: marked(text),
        });
      });
  };

  textContent = () => {
    const { translation, settings, mandarin, english } = this.state;
    if (settings) {
      const content = settingsContent(translation);
      return content;
    } else {
      const content = translation ? mandarin : english;
      return content;
    }
  };

  htmlContent = () => {
    const textContent = this.textContent();
    const cardStyle = this.cardStyle();
    return (
      <article
        className={cardStyle}
        dangerouslySetInnerHTML={{ __html: textContent }}
      ></article>
    );
  };

  cardStyle = () => {
    const { translation, info } = this.state;
    if (info) {
      return "text-card-info";
    }
    if (!translation) {
      return "text-card-chinese";
    } else {
      return "text-card-english";
    }
  };

  toggleText = () => {
    const { translation, settings } = this.state;
    const newTranslation = settings ? translation : !translation;
    this.setState(
      {
        translation: newTranslation,
      },
      this.updateSlug
    );
  };

  render() {
    const { audioList, currentTime, pause, english, settings, translation } =
      this.state;
    const index = this.getIndex();
    const stop = localStorage.getItem("stop")
      ? JSON.parse(localStorage.getItem("stop"))
      : false;
    const clickNplay = localStorage.getItem("clickNplay")
      ? JSON.parse(localStorage.getItem("clickNplay"))
      : true;
    const loop = localStorage.getItem("loop")
      ? JSON.parse(localStorage.getItem("loop"))
      : false;
    const currentTrack = audioList[index];

    if (!english) {
      this.fetchText(index);
    }
    const content = settings
      ? settingsContent(stop, clickNplay, loop, translation)
      : this.htmlContent();
    const playPause = !pause ? "play" : "pause";
    const display = !pause ? { display: "none" } : { display: "inline-block" };

    return (
      <div className="card">
        <div className="current-track">
          <div className="control-wrapper">
            <audio ref={(ref) => (this.playerRef = ref)}>
              <source
                src={data().allAudioFiles[`${index}.mp3`]}
                type="audio/mp3"
              />
              Your browser does not support the audio element.
            </audio>
            <div className="text-wrap" onClick={this.toggleText}>
              {content}
            </div>
            <div className="time">
              <div className="current-time">{currentTime}</div>
              <div className="playing-animation">
                <span className="playing__bar playing__bar1" style={display} />
                <span className="playing__bar playing__bar2" style={display} />
                <span className="playing__bar playing__bar3" style={display} />
              </div>
              <div className="end-time">{currentTrack.duration}</div>
            </div>
            <div ref={(ref) => (this.timelineRef = ref)} id="timeline">
              <div ref={(ref) => (this.playheadRef = ref)} id="playhead"></div>
            </div>
            <div className="controls">
              <button onClick={this.infoApp} className="info" />
              <button onClick={this.backFive} className="back-5" />
              <button onClick={this.playOrPause} className={playPause} />
              <button onClick={this.forwardFive} className="forward-5" />
              <button onClick={this.settingsOpen} className="settings" />
            </div>
          </div>
        </div>
        <div className="play-list">
          {audioList.map((track, key = 0) => (
            <div
              key={key}
              onClick={() => this.clickAudio(key)}
              id={"track" + key}
              className={
                "track " +
                (index === key && !pause ? "current-audio" : "") +
                (index === key && pause ? "play-now" : "")
              }
            >
              <div className="track-info-container">
                <span className="track-name">
                  {translation ? track.nameEn : track.nameCn}
                </span>
              </div>
              <span className="track-duration">
                {index === key ? currentTime : track.duration}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Player;
