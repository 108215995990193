import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo index={0} pageTitle="道德经 - 老子 - 主页" translation={false} />
      <Player chapter={0} translation={false} />
    </>
  );
};

export default index;
