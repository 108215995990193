import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      {/* !! IMPORTANT !! if the pageTitle changes, you must update the 
    robot tags in the Seo component*/}
      <Seo
        index={82}
        pageTitle="The Dao de Jing by Lao Zi - About this App"
        translation={true}
      />
      <Player chapter={0} translation={true} info={true} />
    </>
  );
};

export default index;
