import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo
        index={37}
        pageTitle="The Dao de Jing by Lao Zi - Chapter 37"
        translation={true}
      />
      <Player chapter={37} translation={true} />
    </>
  );
};

export default index;
