import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo index={1} pageTitle="道德经 - 老子 - 第一章" translation={false} />
      <Player chapter={1} translation={false} />
    </>
  );
};

export default index;
