const data = () => {
  function importAllFiles(r) {
    let allFiles = {};
    r.keys().map((item, index) => (allFiles[item.replace("./", "")] = r(item)));
    return allFiles;
  }

  const allAudioFiles = importAllFiles(
    require.context("../audio", false, /\.(mp3)$/)
  );

  return { allAudioFiles };
};

export default data;
