import Seo from "../utils/seo";
import Player from "../components/Player";

const index = () => {
  return (
    <>
      <Seo
        index={18}
        pageTitle="道德经 - 老子 - 第十八章"
        translation={false}
      />
      <Player chapter={18} translation={false} />
    </>
  );
};

export default index;
